<template>
    <el-dialog
        title=" 企业详情"
        :visible.sync="dialogTableVisible"
        width="360px"
    >
        <div v-for="(value, key, index) in comDetail" :key="index">
            <div style="padding-bottom: 16px">{{ key }}：{{ value }}</div>
        </div>
        <div
            style="
                width: 100%;
                padding-top: 10px;
                display: flex;
                justify-content: flex-end;
            "
        >
            <el-button type="primary" @click="closeDialog">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { getCompanyInfoByName } from "@/api";
import { apiCallback } from "../../../../../utils";

export default {
    data() {
        return {
            test: "测试信息",
            dialogTableVisible: false,
            comDetail: {},
        };
    },
    components: {},
    mounted() {},
    methods: {
        init(cName) {
            this.comDetail = {};
            this.getCompanyInfo(cName);
            // this.dialogTableVisible = true
        },
        getCompanyInfo(cName) {
            var that = this;
            var data = {};
            data.cname = cName;
            getCompanyInfoByName(data)
                .then((res) => {
                    const data = apiCallback(res);
                    that.getCampany = {};
                    that.getCampany = data;
                    that.comDetail["认证状态"] = that.accessStatus(
                        that.getCampany.isaccess,
                    );
                    that.comDetail["企业名称"] = that.getCampany.cname;
                    that.comDetail["企业地址"] = that.getCampany.address;
                    that.comDetail["企业电话"] = that.getCampany.cphone;
                    this.dialogTableVisible = true;
                })
                .catch((err) => console.error(err));
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
        accessStatus(status) {
            if (status == 0) {
                return "待审核";
            } else if (status == 1) {
                return "认证成功";
            } else {
                return "认证失败";
            }
        },
    },
};
</script>
<style lang="stylus" scoped></style>
