<template>
    <div style="width: 100%">
        <div class="background_class">
            <div class="message">
                <span class="title">全部企业</span>
                <div class="body">
                    <div class="menu">
                        <el-menu
                            :default-active="activeIndex"
                            class="el-menu-demo"
                            mode="horizontal"
                            @select="handleSelect"
                            text-color="#000"
                            active-text-color="#409EFF"
                        >
                            <el-menu-item index="0" style="font-size: 16px">
                                企业管理
                            </el-menu-item>
                            <el-menu-item index="1" style="font-size: 16px">
                                企业注销
                            </el-menu-item>
                        </el-menu>
                        <el-row>
                            <el-button
                                type="primary"
                                @click="showEntDialog('company')"
                            >
                                添加企业
                            </el-button>
                            <el-button
                                type="primary"
                                @click="showEntDialog('staff')"
                            >
                                加入企业
                            </el-button>
                        </el-row>
                    </div>
                    <div class="inform">
                        <div
                            :style="[
                                activeIndex == '1' ? { display: 'none' } : {},
                            ]"
                        >
                            <el-table
                                border
                                stripe
                                :data="entCompanyInfo"
                                style="width: 100%"
                                :default-sort="{
                                    prop: 'date',
                                    order: 'descending',
                                }"
                                :cell-style="{
                                    textAlign: 'center',
                                    padding: '4px 0',
                                }"
                                :header-cell-style="{
                                    background: '#eef1f6',
                                    color: '#606266',
                                    textAlign: 'center',
                                    padding: '12px 0',
                                }"
                            >
                                <template v-for="item in companyTable">
                                    <el-table-column
                                        show-overflow-tooltip
                                        :prop="item.prop"
                                        :label="item.label"
                                        :width="item.width"
                                        :key="item.prop"
                                        :resizable="item.resizable"
                                    >
                                        <template slot-scope="scope">
                                            <span
                                                v-if="
                                                    item.prop ===
                                                        'certifyTime' ||
                                                    item.prop === 'applyTime'
                                                "
                                                style="line-height: 10px"
                                            >
                                                <p>
                                                    {{
                                                        getDate(
                                                            scope.row[
                                                                item.prop
                                                            ],
                                                        )
                                                    }}
                                                </p>
                                                <p>
                                                    {{
                                                        getTime(
                                                            scope.row[
                                                                item.prop
                                                            ],
                                                        )
                                                    }}
                                                </p>
                                            </span>
                                            <span
                                                v-else-if="
                                                    item.prop ===
                                                    'certifyStatus'
                                                "
                                            >
                                                <p
                                                    :style="
                                                        colorStatus(
                                                            scope.row[
                                                                item.prop
                                                            ],
                                                        )
                                                    "
                                                >
                                                    {{
                                                        accessStatus(
                                                            scope.row[
                                                                item.prop
                                                            ],
                                                        )
                                                    }}
                                                </p>
                                            </span>
                                            <span
                                                v-else-if="
                                                    item.prop === 'isJoin'
                                                "
                                            >
                                                <p
                                                    :style="
                                                        colorStatus(
                                                            scope.row[
                                                                item.prop
                                                            ],
                                                        )
                                                    "
                                                >
                                                    {{
                                                        jionStatus(
                                                            scope.row[
                                                                item.prop
                                                            ],
                                                        )
                                                    }}
                                                </p>
                                            </span>
                                            <span
                                                v-else-if="
                                                    item.prop === 'certifyType'
                                                "
                                            >
                                                <p>
                                                    {{
                                                        scope.row[item.prop] ===
                                                        1
                                                            ? "企业认证"
                                                            : "加入企业"
                                                    }}
                                                </p>
                                            </span>
                                            <span
                                                v-else-if="
                                                    item.prop === 'operate'
                                                "
                                            >
                                                <span
                                                    class="operate-blue"
                                                    v-if="
                                                        scope.row
                                                            .certifyStatus ==
                                                            1 &&
                                                        scope.row.isJoin == 1
                                                    "
                                                    @click="
                                                        showDetailDialog(
                                                            scope.row
                                                                .companyName,
                                                        )
                                                    "
                                                >
                                                    企业详情
                                                </span>
                                                <span
                                                    class="operate-red"
                                                    v-if="
                                                        scope.row
                                                            .certifyStatus == 0
                                                    "
                                                    @click="
                                                        isWithdrawCertify(
                                                            scope.row.cid,
                                                            scope.row
                                                                .certifyType,
                                                        )
                                                    "
                                                >
                                                    {{
                                                        scope.row
                                                            .certifyType === 1
                                                            ? "撤回企业认证"
                                                            : "撤回加入企业"
                                                    }}
                                                </span>
                                                <div
                                                    v-if="
                                                        scope.row
                                                            .certifyStatus == 2
                                                    "
                                                    style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                    "
                                                >
                                                    <span
                                                        class="operate-blue"
                                                        @click="
                                                            watchDesc(
                                                                scope.row.desc,
                                                            )
                                                        "
                                                    >
                                                        审核意见
                                                    </span>
                                                    <span
                                                        class="operate-blue"
                                                        @click="
                                                            showEntDialog(
                                                                'company',
                                                            )
                                                        "
                                                    >
                                                        重新认证
                                                    </span>
                                                </div>
                                            </span>
                                            <span v-else>
                                                {{ scope.row[item.prop] }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <div slot="empty">
                                    <el-empty :image-size="200"></el-empty>
                                </div>
                            </el-table>
                        </div>
                        <div
                            :style="[
                                activeIndex == '0' ? { display: 'none' } : {},
                            ]"
                        >
                            <el-table
                                border
                                stripe
                                :data="entCompanyLogout"
                                style="width: 100%"
                                :default-sort="{
                                    prop: 'date',
                                    order: 'descending',
                                }"
                                :header-cell-style="{
                                    background: '#eef1f6',
                                    color: '#606266',
                                    textAlign: 'center',
                                    padding: '12px 0',
                                }"
                            >
                                <template v-for="item in companyLogout">
                                    <el-table-column
                                        show-overflow-tooltip
                                        :prop="item.prop"
                                        :label="item.label"
                                        :width="item.width"
                                        :key="item.prop"
                                        :resizable="item.resizable"
                                    ></el-table-column>
                                </template>
                                <div slot="empty">
                                    <el-empty :image-size="200"></el-empty>
                                </div>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sendCompany-dialog
            v-if="entDialogVisible"
            ref="sendCompanyDialog"
            @refreshCompanyList="getCompanyMess"
            @dialogClose="init"
            :title="entDialogTitle"
        ></sendCompany-dialog>
        <comDetail-dialog
            v-if="detailDialogVisible"
            ref="ComDetailDialog"
        ></comDetail-dialog>
        <loginTimeout-dialog
            v-if="loginTimeoutDialogVisible"
            ref="loginTimeoutDialog"
        ></loginTimeout-dialog>
    </div>
</template>
<script>
import sendCompanyDialog from "../common/sendCompany";
import ComDetailDialog from "./components/ComDetailDialog";
import loginTimeoutDialog from "../common/LoginTimeout";
import { companyLogout, getUserApplyCompany } from "@/api";
import { apiCallback } from "../../../../utils";
export default {
    data() {
        return {
            activeIndex: "0",
            entDialogVisible: false,
            detailDialogVisible: false,
            entDialogTitle: "",
            loginTimeoutDialogVisible: false,
            entCompanyInfo: [],
            entCompanyLogout: [],
            companyTable: [
                {
                    prop: "companyName",
                    label: "企业名称",
                    resizable: true,
                },
                // {
                //     prop: 'companyId',
                //     label: "企业ID",
                //     width: "120",
                //     resizable: true,
                // },
                {
                    prop: "certifyStatus",
                    label: "认证状态",
                    width: "90",
                    resizable: true,
                },
                {
                    prop: "certifyTime",
                    label: "认证时间",
                    resizable: true,
                    width:"120"
                },
                {
                    prop: "certifyType",
                    label: "认证类型",
                    width: "90",
                },
                {
                    prop: "adminName",
                    label: "管理员",
                    width: "120",
                    resizable: true,
                },
                // {
                //     prop: "isJoin",
                //     label: "加入状态",
                //     width: "90",
                //     resizable: true,
                // },
                {
                    prop: "applyTime",
                    label: "处理时间",
                    width: "110",
                    resizable: true,
                },
                {
                    prop: "companyRole",
                    label: "企业角色",
                    width: "100",
                    resizable: true,
                },
                {
                    prop: "operate",
                    label: "操作",
                    width: "160",
                    resizable: true,
                },
            ],
            companyLogout: [
                {
                    prop: "companyName",
                    label: "企业名称",
                    width: "120",
                    resizable: true,
                },
                {
                    prop: "logoutStatus",
                    label: "注销状态",
                    width: "120",
                    resizable: true,
                },
                {
                    prop: "applyTime",
                    label: "注销申请时间",
                    width: "120",
                    resizable: true,
                },
                {
                    prop: "adminName",
                    label: "管理员",
                    width: "120",
                    resizable: true,
                },
                {
                    prop: "isJoin",
                    label: "加入状态",
                    width: "120",
                    resizable: true,
                },
                {
                    prop: "joinTime",
                    label: "加入时间",
                    width: "120",
                    resizable: true,
                },
                {
                    prop: "companyRole",
                    label: "企业角色",
                    width: "100",
                    resizable: true,
                },
                {
                    prop: "operate",
                    label: "操作",
                    width: "100",
                    resizable: true,
                },
            ],
        };
    },
    components: {
        sendCompanyDialog,
        ComDetailDialog,
        loginTimeoutDialog,
    },
    mounted() {
        window.sessionStorage.setItem("activePath", "/user/all-company");
        this.init();
    },
    methods: {
        init() {
            this.activeIndex = "0";
            this.getCompanyMess();
        },
        handleSelect(key, keyPath) {
            this.activeIndex = key;
        },
        showEntDialog(type) {
            this.entDialogVisible = true;
            this.entDialogTitle = type === "company" ? "企业认证" : "加入企业";
            this.$nextTick(() => {
                this.$refs.sendCompanyDialog.init();
            });
        },
        showDetailDialog(cName) {
            this.detailDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.ComDetailDialog.init(cName);
            });
        },
        //认证和申请的公司
        async getCompanyMess() {
            var that = this;
            await getUserApplyCompany({
                id: this.$store.state.user.userId,
            })
                .then((res) => {
                    const data = apiCallback(res);
                    that.getMessList(data);
                })
                .catch((err) => console.error(err));
        },
        getMessList(list) {
            console.log(list);
            this.entCompanyInfo = [];
            list.forEach((item) => {
                if (!item.cname) {
                    return;
                }
                let data = {};
                data.companyName = item.cname;
                data.certifyStatus = item.isaccess;
                data.cid = item.cid;
                data.certifyTime = this.formatterTime(item.createTime); //认证时间
                data.certifyType = item.role === "staff" ? 0 : 1;
                data.desc=item.desc;
                if (item.isaccess == 1) {
                    data.adminName = item.uname;
                    data.isJoin = item.ustates;
                } else {
                    data.adminName = "";
                    data.isJoin = -1;
                }
                if (item.role == "Management") {
                    data.companyRole = "管理员";
                    data.applyTime = this.formatterTime(item.updateTime); //申请审核时间
                } else if (item.role == "staff") {
                    data.companyRole = "普通员工";
                    data.applyTime = this.formatterTime(item.updated); //申请审核时间
                }
                this.entCompanyInfo.push(data);
            });
            // console.log(this.entCompanyInfo)
        },
        //认证状态转化
        accessStatus(status) {
            if (status == 0) {
                return "待审核";
            } else if (status == 1) {
                return "认证成功";
            } else {
                return "认证失败";
            }
        },
        colorStatus(status) {
            if (status == 0) {
                return { color: "#000" };
            } else if (status == 1) {
                return { color: "green" };
            } else if (status == 2) {
                return { color: "red" };
            }
        },
        //加入状态转化
        jionStatus(status) {
            if (status == 0) {
                return "待确认";
            } else if (status == 1) {
                return "已加入";
            } else if (status == 2) {
                return "拒绝加入";
            } else {
                return "";
            }
        },
        //时间转化
        formatterTime(time) {
            if (time && time != "-") {
                var data = time.substr(0, 10);
                var clock = time.substr(11, 8);
                return data + clock;
            }
            return "-";
        },
        getDate(time) {
            if (time && time != "-") {
                return time.substr(0, 10);
            }
            return "-";
        },
        getTime(time) {
            if (time && time != "-") {
                return time.substr(10, 8);
            }
            return "";
        },
        watchDesc(desc) {
            this.$alert(desc, "审核意见", {
                confirmButtonText: "确定",
                callback: (action) => {},
            });
        },
        //撤回企业认证申请或者加入企业申请
        isWithdrawCertify(cid, certifyType) {
            this.$confirm(
                `是否撤回该${
                    certifyType === 0 ? "加入企业申请" : "企业认证申请"
                }`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    this.withdrawCertify(
                        cid,
                        this.$store.state.user.userId,
                        certifyType,
                    );
                })
                .catch(() => {
                    //点击取消执行
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        async withdrawCertify(cid, uid, cancelType) {
            // console.log(this.companyId,this.$store.state.user.userId)
            if (!cid || !uid) {
                this.$message.error("error");
                return;
            }
            var that = this;
            const data = {
                uid,
                cid,
                cancelType,
            };
            await companyLogout(data)
                .then((res) => {
                    var data = res.data;
                    if (data.status) {
                        that.$message.success("操作成功");
                        that.getCompanyMess();
                    } else {
                        this.$message.error(data.msg);
                    }
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style lang="stylus" scoped>
.background_class {
  display: flex;
  background-color: #ffffff;
  height: 100%;
  width: auto;
  justify-content: flex-start;
}

.message {
  /* margin: 30px 0 8px 15px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* font-size: 16px; */
  /* justify-content: center; */
}

.title {
    font-size: 1.68rem;
    font-weight: 700;
  padding: 1.68rem;
  background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
    color:white;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  // color: #989898;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-bottom-color: #e5e5e5;
}

.company-chance {
  width: 200px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px;
  font-size: 16px;
  color: #ccc;

  .font-color {
    color: #000;
  }
}

.inform {
  width:100%;
  margin-top: 30px;
  // margin-left: 20px;
  font-size: 12px;
  color: #989898;
}

.operate-blue {
  cursor: pointer;
  color: #409EFF;
  user-select: none;
}

.operate-red {
  cursor: pointer;
  color: #F56C6C;
  user-select: none;
}
</style>
